/* -------------------------------- 

Primary style

-------------------------------- */

* {
    box-sizing: border-box;
  
    &::after, &::before {
      box-sizing: border-box;
    }
  }
  
  html {
    font-size: 62.5%;
  }
  
  @media only screen and (max-width: 959px) {
    body.nav-on-left.overflow-hidden {
      overflow: hidden;
    }
  }
  
  a {
    color: #888;
    text-decoration: none;
  }
  
  img {
    /* make images responsive */
    max-width: 100%;
  }
  
  input {
    font-family: sans-serif;
    font-size: 1.6rem;
  
    &[type="search"] {
      &::-ms-clear {
        /* removes close icon - IE */
        display: none;
      }
  
      &::-webkit-search-decoration, &::-webkit-search-cancel-button, &::-webkit-search-results-button, &::-webkit-search-results-decoration {
        display: none;
      }
    }
  }
  
  /* -------------------------------- 
  
  Main components 
  
  -------------------------------- */
  
  .cd-main-content, .cd-main-header {
    /* Force Hardware Acceleration in WebKit */
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    will-change: transform;
  }
  
  .cd-main-content, .cd-main-header {
    position: relative;
    -webkit-transition: -webkit-transform 0.3s;
    -moz-transition: -moz-transform 0.3s;
    transition: transform 0.3s;
  }
  
  @media only screen and (max-width: 959px) {
    .cd-main-content.nav-is-visible, .cd-main-header.nav-is-visible {
      -webkit-transform: translateX(-260px);
      -moz-transform: translateX(-260px);
      -ms-transform: translateX(-260px);
      -o-transform: translateX(-260px);
      transform: translateX(-260px);
    }
  
    .nav-on-left {
      .cd-main-content.nav-is-visible, .cd-main-header.nav-is-visible {
        -webkit-transform: translateX(260px);
        -moz-transform: translateX(260px);
        -ms-transform: translateX(260px);
        -o-transform: translateX(260px);
        transform: translateX(260px);
      }
    }
  }
  
  .cd-main-content {
    min-height: 100vh;
    z-index: 2;
  }
  
  .cd-main-header {
    height: 50px;
    background: #ffffff;
    z-index: 3;
  }
  
  .nav-is-fixed .cd-main-header {
    /* add .nav-is-fixed class to body if you want a fixed navigation on > 1170px */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
  
  @media only screen and (min-width: 960px) {
    .cd-main-header {
      height: 80px;
  
      &::after {
        clear: both;
        content: "";
        display: table;
      }
    }
  }
  
  .cd-logo {
    position: absolute;
    top: 12px;
    left: 5%;
  
    img {
      display: block;
    }
  }
  
  @media only screen and (max-width: 959px) {
    .nav-on-left .cd-logo {
      left: auto;
      right: 5%;
    }
  }
  
  @media only screen and (min-width: 960px) {
    .cd-logo {
      top: 26px;
      left: 4em;
    }
  }
  
  .cd-header-buttons {
    position: absolute;
    display: inline-block;
    top: 6px;
    right: 2%;
  
    li {
      display: inline-block;
    }
  }
  
  @media only screen and (max-width: 959px) {
    .nav-on-left .cd-header-buttons {
      right: auto;
      left: 5%;
  
      li {
        float: right;
      }
    }
  }
  
  @media only screen and (min-width: 960px) {
    .cd-header-buttons {
      top: 18px;
      right: 4em;
    }
  }
  
  .cd-search-trigger, .cd-nav-trigger {
    position: relative;
    display: block;
    width: 44px;
    height: 44px;
    overflow: hidden;
    white-space: nowrap;
  
    /* hide text */
    color: transparent;
    z-index: 3;
  }
  
  a.cd-nav-trigger {
    &:link {
      position: relative;
      display: block;
      width: 44px;
      height: 44px;
      overflow: hidden;
      white-space: nowrap;
  
      /* hide text */
      color: transparent;
      z-index: 3;
    }
  
    position: relative;
    display: block;
    width: 44px;
    height: 44px;
    overflow: hidden;
    white-space: nowrap;
  
    /* hide text */
    color: transparent;
    z-index: 3;
  }
  
  .cd-search-trigger {
    &::before, &::after {
      /* search icon */
      content: '';
      position: absolute;
      -webkit-transition: opacity 0.3s;
      -moz-transition: opacity 0.3s;
      transition: opacity 0.3s;
  
      /* Force Hardware Acceleration in WebKit */
      -webkit-transform: translateZ(0);
      -moz-transform: translateZ(0);
      -ms-transform: translateZ(0);
      -o-transform: translateZ(0);
      transform: translateZ(0);
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }
  
    &::before {
      /* lens */
      top: 11px;
      left: 11px;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      border: 3px solid #2e3233;
    }
  
    &::after {
      /* handle */
      height: 3px;
      width: 8px;
      background: #2e3233;
      bottom: 14px;
      right: 11px;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  
    span {
      /* container for the X icon */
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
  
      &::before, &::after {
        /* close icon */
        content: '';
        position: absolute;
        display: inline-block;
        height: 3px;
        width: 22px;
        top: 50%;
        margin-top: -2px;
        left: 50%;
        margin-left: -11px;
        background: #2e3233;
        opacity: 0;
  
        /* Force Hardware Acceleration in WebKit */
        -webkit-transform: translateZ(0);
        -moz-transform: translateZ(0);
        -ms-transform: translateZ(0);
        -o-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
        -moz-transition: opacity 0.3s, -moz-transform 0.3s;
        transition: opacity 0.3s, transform 0.3s;
      }
  
      &::before {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }
  
      &::after {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
    }
  
    &.search-is-visible {
      &::before, &::after {
        /* hide search icon */
        opacity: 0;
      }
  
      span {
        &::before, &::after {
          /* show close icon */
          opacity: 1;
        }
  
        &::before {
          -webkit-transform: rotate(135deg);
          -moz-transform: rotate(135deg);
          -ms-transform: rotate(135deg);
          -o-transform: rotate(135deg);
          transform: rotate(135deg);
        }
  
        &::after {
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
    }
  }
  
  .cd-nav-trigger {
    span {
      /* hamburger icon in CSS */
      position: absolute;
      display: inline-block;
      height: 3px;
      width: 24px;
      background: #2e3233;
  
      &::before, &::after {
        /* hamburger icon in CSS */
        position: absolute;
        display: inline-block;
        height: 3px;
        width: 24px;
        background: #2e3233;
      }
  
      /* line in the center */
      position: absolute;
      top: 50%;
      right: 10px;
      margin-top: -2px;
      -webkit-transition: background 0.3s 0.3s;
      -moz-transition: background 0.3s 0.3s;
      transition: background 0.3s 0.3s;
  
      &::before, &::after {
        /* other 2 lines */
        content: '';
        right: 0;
  
        /* Force Hardware Acceleration in WebKit */
        -webkit-transform: translateZ(0);
        -moz-transform: translateZ(0);
        -ms-transform: translateZ(0);
        -o-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transform-origin: 0% 50%;
        -moz-transform-origin: 0% 50%;
        -ms-transform-origin: 0% 50%;
        -o-transform-origin: 0% 50%;
        transform-origin: 0% 50%;
        -webkit-transition: -webkit-transform 0.3s 0.3s;
        -moz-transition: -moz-transform 0.3s 0.3s;
        transition: transform 0.3s 0.3s;
      }
  
      &::before {
        /* menu icon top line */
        top: -6px;
      }
  
      &::after {
        /* menu icon bottom line */
        top: 6px;
      }
    }
  
    &.nav-is-visible span {
      /* hide line in the center */
      background: rgba(46, 50, 51, 0);
  
      &::before, &::after {
        /* keep visible other 2 lines */
        background: #2e3233;
      }
  
      &::before {
        -webkit-transform: translateX(4px) translateY(-3px) rotate(45deg);
        -moz-transform: translateX(4px) translateY(-3px) rotate(45deg);
        -ms-transform: translateX(4px) translateY(-3px) rotate(45deg);
        -o-transform: translateX(4px) translateY(-3px) rotate(45deg);
        transform: translateX(4px) translateY(-3px) rotate(45deg);
      }
  
      &::after {
        -webkit-transform: translateX(4px) translateY(2px) rotate(-45deg);
        -moz-transform: translateX(4px) translateY(2px) rotate(-45deg);
        -ms-transform: translateX(4px) translateY(2px) rotate(-45deg);
        -o-transform: translateX(4px) translateY(2px) rotate(-45deg);
        transform: translateX(4px) translateY(2px) rotate(-45deg);
      }
    }
  }
  
  @media only screen and (min-width: 960px) {
    .cd-nav-trigger {
      display: none;
    }
  
    a.cd-nav-trigger {
      &:link {
        display: none;
      }
  
      display: none;
    }
  }
  
  .cd-primary-nav {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 260px;
    background: #2e3233;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 4;
    -webkit-transition: -webkit-transform 0.3s;
    -moz-transition: -moz-transform 0.3s;
    transition: transform 0.3s;
  
    ul {
      position: fixed;
      top: 0;
      right: 0;
      height: 100%;
      width: 260px;
      background: #2e3233;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      z-index: 4;
      -webkit-transition: -webkit-transform 0.3s;
      -moz-transition: -moz-transform 0.3s;
      transition: transform 0.3s;
  
      &:after {
        background-color: rgba(0, 0, 0, 0.5);
        width: 100%;
        position: fixed;
        top: 80px;
        left: 0;
        height: 350px;
        z-index: -1;
      }
    }
  
    a {
      display: block;
      padding: 0;
      color: #ffffff;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      overflow: hidden;
      text-overflow: ellipsis;
  
      /*white-space: nowrap;*/
      border-bottom: 1px solid #3a3f40;
      -webkit-transform: translateZ(0);
      -moz-transform: translateZ(0);
      -ms-transform: translateZ(0);
      -o-transform: translateZ(0);
      transform: translateZ(0);
      will-change: transform, opacity;
      -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
      -moz-transition: -moz-transform 0.3s, opacity 0.3s;
      transition: transform 0.3s, opacity 0.3s;
    }
  
    ul {
      a {
        display: block;
        padding: 0;
        color: #ffffff;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        overflow: hidden;
        text-overflow: ellipsis;
  
        /*white-space: nowrap;*/
        border-bottom: 1px solid #3a3f40;
        -webkit-transform: translateZ(0);
        -moz-transform: translateZ(0);
        -ms-transform: translateZ(0);
        -o-transform: translateZ(0);
        transform: translateZ(0);
        will-change: transform, opacity;
        -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
        -moz-transition: -moz-transform 0.3s, opacity 0.3s;
        transition: transform 0.3s, opacity 0.3s;
      }
  
      li ol a {
        border-bottom: 0;
        background: url(../images/common/arw03.png) no-repeat left 4px;
        padding-left: 12px;
        height: auto;
        line-height: 1.2;
        margin-top: 15px;
        font-size: 13px;
        font-weight: 400;
      }
  
      a {
        height: 50px;
        line-height: 50px;
      }
    }
  
    &.is-hidden, ul.is-hidden {
      /* secondary navigations hidden by default */
      -webkit-transform: translateX(100%);
      -moz-transform: translateX(100%);
      -ms-transform: translateX(100%);
      -o-transform: translateX(100%);
      transform: translateX(100%);
    }
  
    &.moves-out > li > a, ul.moves-out > li > a {
      /* push the navigation items to the left - and lower down opacity - when secondary nav slides in */
      -webkit-transform: translateX(-100%);
      -moz-transform: translateX(-100%);
      -ms-transform: translateX(-100%);
      -o-transform: translateX(-100%);
      transform: translateX(-100%);
      opacity: 0;
    }
  
    .see-all a {
      /* different style for the See all button on mobile and tablet */
      color: #888;
    }
  
    .cd-nav-gallery .cd-nav-item, .cd-nav-icons .cd-nav-item {
      /* items with picture (or icon) and title */
      height: 80px;
      line-height: 80px;
    }
  
    .cd-nav-gallery .cd-nav-item p, .cd-nav-icons .cd-nav-item p {
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
    }
  
    .cd-nav-gallery .cd-nav-item {
      padding-left: 90px;
  
      img {
        position: absolute;
        display: block;
        height: 40px;
        width: auto;
        left: 20px;
        top: 50%;
        margin-top: -20px;
      }
    }
  
    .cd-nav-icons .cd-nav-item {
      /*padding-left: 75px;*/
  
      p {
        color: #2e3233;
        font-size: 1.3rem;
  
        /* hide description on small devices */
        display: none;
      }
  
      &::before {
        /* item icon */
        content: '';
        display: block;
        position: absolute;
        left: 20px;
        top: 50%;
        margin-top: -20px;
        width: 40px;
        height: 40px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 40px 40px;
      }
    }
  }
  
  /*---
  .cd-primary-nav ul {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);	
  }--*/
  
  @media all and (-ms-high-contrast: none) {
    .cd-primary-nav ul:after {
      top: 125px;
    }
  
    #header_inner.fixed .cd-primary-nav ul:after {
      top: 80px;
    }
  }
  
  @media (max-width: 960px) {
    .cd-primary-nav {
      a, ul a {
        height: 50px;
        line-height: 50px;
      }
    }
  }
  
  @media only screen and (max-width: 959px) {
    .nav-on-left .cd-primary-nav {
      right: auto;
      left: 0;
  
      ul {
        right: auto;
        left: 0;
      }
    }
  }
  
  @media only screen and (max-width: 959px) {
    .cd-primary-nav {
      /* by default .cd-primary-nav is hidden - trick for iOS devices where you can see the navigation if you pull down */
      visibility: hidden;
      -webkit-transition: visibility 0s 0.3s;
      -moz-transition: visibility 0s 0.3s;
      transition: visibility 0s 0.3s;
  
      &.nav-is-visible {
        visibility: visible;
        -webkit-transition: visibility 0s 0s;
        -moz-transition: visibility 0s 0s;
        transition: visibility 0s 0s;
      }
  
      .cd-nav-icons .cd-nav-item {
        &::before {
          display: none;
        }
  
        p {
          color: #fff;
          font-size: 1.4rem;
          display: inline-block;
        }
      }
    }
  }
  
  @media only screen and (min-width: 960px) {
    .cd-primary-nav {
      position: static;
      padding: 6px 0 0;
      height: auto;
      float: right;
      overflow: visible;
      background: transparent;
  
      &::after {
        clear: both;
        content: "";
        display: table;
      }
  
      &.moves-out > li > a {
        /* reset mobile style */
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
      }
  
      ul {
        position: static;
        height: auto;
        width: auto;
        background: transparent;
        overflow: visible;
        z-index: -999;
  
        &.is-hidden {
          /* reset mobile style */
          -webkit-transform: translateX(0);
          -moz-transform: translateX(0);
          -ms-transform: translateX(0);
          -o-transform: translateX(0);
          transform: translateX(0);
        }
  
        &.moves-out > li > a {
          /* reset mobile style */
          -webkit-transform: translateX(0);
          -moz-transform: translateX(0);
          -ms-transform: translateX(0);
          -o-transform: translateX(0);
          transform: translateX(0);
          opacity: 1;
        }
      }
  
      > li {
        float: left;
  
        > a {
          /* main navigation buttons style */
          position: relative;
          display: inline-block;
          height: 50px;
          line-height: 50px;
          color: #2e3233;
          overflow: visible;
          border-bottom: none;
          -webkit-transition: color 0.3s, box-shadow 0.3s;
          -moz-transition: color 0.3s, box-shadow 0.3s;
          transition: color 0.3s, box-shadow 0.3s;
  
          &:hover span {
            padding-bottom: 5px;
            border-bottom: 3px solid #ddd;
          }
  
          &.selected {
            color: #888;
            box-shadow: inset 0 -2px 0 #69aa6f;
          }
        }
      }
  
      .go-back, .see-all {
        display: none;
      }
  
      .cd-secondary-nav, .cd-nav-gallery, .cd-nav-icons {
        /* dropdown menu style */
        position: absolute;
        top: 80px;
        left: 0;
        margin: auto;
        max-width: 1140px;
        padding: 30px 0;
  
            /*
            -webkit-transform: translateX(0);
            -moz-transform: translateX(0);
            -ms-transform: translateX(0);
            -o-transform: translateX(0);
            transform: translateX(0);
            */
        -webkit-transition: opacity .3s 0s, visibility 0s 0s;
        -moz-transition: opacity .3s 0s, visibility 0s 0s;
        transition: opacity .3s 0s, visibility 0s 0s;
      }
  
      .cd-secondary-nav::after, .cd-nav-gallery::after, .cd-nav-icons::after {
        clear: both;
        content: "";
        display: table;
      }
  
      .cd-secondary-nav.is-hidden, .cd-nav-gallery.is-hidden, .cd-nav-icons.is-hidden {
        opacity: 0;
        visibility: hidden;
        -webkit-transition: opacity .3s 0s, visibility 0s .3s;
        -moz-transition: opacity .3s 0s, visibility 0s .3s;
        transition: opacity .3s 0s, visibility 0s .3s;
      }
  
      .cd-secondary-nav > .see-all, .cd-nav-gallery > .see-all, .cd-nav-icons > .see-all {
        /* this is the BIG See all button at the bottom of the dropdown menu */
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 80px;
        width: 100%;
        overflow: hidden;
  
        /* reset some inherited style */
        margin: 0;
        padding: 0;
      }
  
      .cd-secondary-nav > .see-all a, .cd-nav-gallery > .see-all a, .cd-nav-icons > .see-all a {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        font-size: 2.2rem;
        font-weight: bold;
        text-align: center;
        line-height: 80px;
        border-top: 1px solid #e2e3df;
  
        /* reset some inherited style */
        border-bottom: none;
        margin: 0;
        padding: 0;
        -webkit-transition: color 0.2s, background 0.2s, border 0.2s;
        -moz-transition: color 0.2s, background 0.2s, border 0.2s;
        transition: color 0.2s, background 0.2s, border 0.2s;
      }
  
      .cd-secondary-nav > .see-all a:hover, .cd-nav-gallery > .see-all a:hover, .cd-nav-icons > .see-all a:hover {
        background: #2e3233;
        border-color: #2e3233;
        color: #ffffff;
      }
  
      .cd-secondary-nav {
        > li {
          /* change the height according to your needs - you can even set height: auto */
          height: 340px;
  
          /* here you set the number of columns - use width percentage */
          width: 23%;
          float: left;
          margin-right: 2.66%;
          border-right: 1px solid #e2e3df;
          overflow: hidden;
          overflow-x: hidden;
          overflow-y: auto;
          -webkit-overflow-scrolling: touch;
  
          &:nth-child(4n+2) {
            /* +2 because we have 2 list items with display:none */
            margin-right: 0;
            border-right: none;
          }
  
          > a {
            /* secondary nav title */
            color: #888;
            font-weight: bold;
            font-size: 1.6rem;
            margin-bottom: .6em;
          }
        }
  
        a {
          height: 30px;
          line-height: 30px;
          padding: 0 18% 0 0;
          color: #2e3233;
          border-bottom: none;
          font-size: 1.4rem;
  
          &:hover {
            color: #888;
          }
        }
  
        ul {
          /* Force Hardware Acceleration in WebKit */
          -webkit-transform: translateZ(0);
          -moz-transform: translateZ(0);
          -ms-transform: translateZ(0);
          -o-transform: translateZ(0);
          transform: translateZ(0);
  
          ul {
            /* tertiary navigation */
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
  
            &.is-hidden {
              -webkit-transform: translateX(100%);
              -moz-transform: translateX(100%);
              -ms-transform: translateX(100%);
              -o-transform: translateX(100%);
              transform: translateX(100%);
            }
  
            .go-back {
              display: block;
  
              a {
                color: transparent;
              }
            }
  
            .see-all {
              display: block;
            }
          }
        }
  
        .moves-out > li > a {
          /* push the navigation items to the left - and lower down opacity - when tertiary nav slides in */
          -webkit-transform: translateX(-100%);
          -moz-transform: translateX(-100%);
          -ms-transform: translateX(-100%);
          -o-transform: translateX(-100%);
          transform: translateX(-100%);
        }
      }
  
      .cd-nav-gallery {
        > li {
          /* set here number of columns - use width percentage */
          width: 25%;
          height: 300px;
          float: left;
          margin: 0;
          padding: 0 2%;
          border-right: 1px solid #333;
  
          img {
            width: 100%;
          }
  
          &:last-child {
            /* +2 because we have two additional list items with display:none */
            border-right: none;
          }
        }
  
        .cd-nav-item {
          border-bottom: none;
          padding: 0;
          height: auto;
          line-height: 1.2;
  
          img {
            position: static;
            margin-top: 0;
            height: auto;
            width: 100%;
            margin-bottom: .6em;
          }
  
          p {
            font-weight: bold;
            padding: 0;
            font-size: 14px;
            margin: 10px 0;
          }
        }
      }
  
      .cd-nav-icons {
        li {
          /* set here number of columns - use width percentage */
          width: 32%;
          float: left;
          margin: 0 2% 20px 0;
  
          &:nth-child(3n+2) {
            /* +2 because we have two additional list items with display:none */
            margin-right: 0;
          }
        }
  
        .cd-nav-item {
          border-bottom: none;
          height: 60px;
          line-height: 1.2;
          padding: 24px 0 0 0;
          text-align: center;
          position: relative;
  
          &:hover {
            background: #f6f6f5;
          }
  
          p {
            color: #888;
            font-weight: bold;
            display: block;
          }
  
          &::before {
            left: 25px;
          }
        }
      }
    }
  }
  
  .has-children > a, .go-back a {
    position: relative;
  }
  
  .has-children > a {
    &::before, &::after {
      /* arrow icon in CSS - for element with nested unordered lists */
      content: '';
      position: absolute;
      top: 50%;
      margin-top: -1px;
      display: inline-block;
      height: 2px;
      width: 10px;
      background: #464c4e;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }
  }
  
  .go-back a {
    &::before, &::after {
      /* arrow icon in CSS - for element with nested unordered lists */
      content: '';
      position: absolute;
      top: 50%;
      margin-top: -1px;
      display: inline-block;
      height: 2px;
      width: 10px;
      background: #464c4e;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }
  }
  
  .has-children > a::before, .go-back a::before {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  
  .has-children > a::after, .go-back a::after {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  
  @media only screen and (min-width: 960px) {
    .has-children > a {
      &::before, &::after {
        background: #c9cbc4;
      }
    }
  
    .go-back a {
      &::before, &::after {
        background: #c9cbc4;
      }
    }
  
    .has-children > a:hover {
      &::before, &::after {
        background: #69aa6f;
      }
    }
  
    .go-back a:hover {
      &::before, &::after {
        background: #69aa6f;
      }
    }
  }
  
  .has-children > a {
    &::before, &::after {
      /* arrow goes on the right side - children navigation */
      right: 20px;
      -webkit-transform-origin: 9px 50%;
      -moz-transform-origin: 9px 50%;
      -ms-transform-origin: 9px 50%;
      -o-transform-origin: 9px 50%;
      transform-origin: 9px 50%;
    }
  }
  
  .cd-primary-nav .go-back a {
    padding-left: 40px;
  
    &::before, &::after {
      /* arrow goes on the left side - go back button */
      left: 20px;
      -webkit-transform-origin: 1px 50%;
      -moz-transform-origin: 1px 50%;
      -ms-transform-origin: 1px 50%;
      -o-transform-origin: 1px 50%;
      transform-origin: 1px 50%;
    }
  }
  
  @media only screen and (min-width: 960px) {
    .has-children > a {
      &::before, &::after {
        right: 15%;
      }
    }
  
    /*  .cd-primary-nav > .has-children > a {
        padding-right: 30px !important;
      }*/
  
    .cd-primary-nav > .has-children > a {
      &::before, &::after {
        width: 9px;
        -webkit-transform-origin: 50% 50%;
        -moz-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
        -o-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        background: #c9cbc4;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transition: width 0.3s, -webkit-transform 0.3s;
        -moz-transition: width 0.3s, -moz-transform 0.3s;
        transition: width 0.3s, transform 0.3s;
      }
  
      &::before {
        right: 12px;
      }
  
      &::after {
        right: 7px;
      }
  
      &.selected {
        &::before, &::after {
          width: 14px;
        }
  
        &::before {
          -webkit-transform: translateX(5px) rotate(-45deg);
          -moz-transform: translateX(5px) rotate(-45deg);
          -ms-transform: translateX(5px) rotate(-45deg);
          -o-transform: translateX(5px) rotate(-45deg);
          transform: translateX(5px) rotate(-45deg);
        }
  
        &::after {
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
    }
  
    .cd-secondary-nav > .has-children > a {
      &::before, &::after {
        /* remove arrows on secondary nav titles */
        display: none;
      }
    }
  
    .cd-primary-nav {
      > .has-children > a {
        &::before, &::after {
          display: none;
        }
      }
  
      .go-back a {
        padding-left: 20px;
  
        &::before, &::after {
          left: 1px;
        }
      }
    }
  }
  
  .cd-search {
    position: absolute;
    height: 50px;
    width: 100%;
    top: 50px;
    left: 0;
    z-index: 3;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity .3s 0s, visibility 0s .3s;
    -moz-transition: opacity .3s 0s, visibility 0s .3s;
    transition: opacity .3s 0s, visibility 0s .3s;
  
    form {
      height: 100%;
      width: 100%;
    }
  
    input {
      border-radius: 0;
      border: none;
      background: #ffffff;
      height: 100%;
      width: 100%;
      padding: 0 5%;
      box-shadow: inset 0 1px 0 #e2e3df, 0 3px 6px rgba(0, 0, 0, 0.05);
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      -o-appearance: none;
      appearance: none;
  
      &::-webkit-input-placeholder, &::-moz-placeholder, &:-moz-placeholder, &:-ms-input-placeholder {
        color: #c9cbc4;
      }
  
      &:focus {
        outline: none;
      }
    }
  
    &.is-visible {
      opacity: 1;
      visibility: visible;
      -webkit-transition: opacity .3s 0s, visibility 0s 0s;
      -moz-transition: opacity .3s 0s, visibility 0s 0s;
      transition: opacity .3s 0s, visibility 0s 0s;
    }
  }
  
  .nav-is-fixed .cd-search {
    position: fixed;
  }
  
  @media only screen and (min-width: 960px) {
    .cd-search {
      height: 120px;
      top: 80px;
  
      input {
        padding: 0 2em;
        font-size: 3.2rem;
        font-weight: 300;
      }
    }
  }
  
  .cd-overlay {
    /* shadow layer visible when navigation is active */
    position: fixed;
    z-index: 2;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.6);
    visibility: hidden;
    opacity: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: opacity 0.3s 0s, visibility 0s 0.3s, -webkit-transform 0.3s 0s;
    -moz-transition: opacity 0.3s 0s, visibility 0s 0.3s, -moz-transform 0.3s 0s;
    transition: opacity 0.3s 0s, visibility 0s 0.3s, transform 0.3s 0s;
  
    &.is-visible {
      opacity: 1;
      visibility: visible;
      -webkit-transition: opacity 0.3s 0s, visibility 0s 0s, -webkit-transform 0.3s 0s;
      -moz-transition: opacity 0.3s 0s, visibility 0s 0s, -moz-transform 0.3s 0s;
      transition: opacity 0.3s 0s, visibility 0s 0s, transform 0.3s 0s;
    }
  }
  
  @media only screen and (max-width: 959px) {
    .cd-overlay.is-visible {
      -webkit-transform: translateX(-260px);
      -moz-transform: translateX(-260px);
      -ms-transform: translateX(-260px);
      -o-transform: translateX(-260px);
      transform: translateX(-260px);
    }
  
    .nav-on-left .cd-overlay.is-visible {
      -webkit-transform: translateX(260px);
      -moz-transform: translateX(260px);
      -ms-transform: translateX(260px);
      -o-transform: translateX(260px);
      transform: translateX(260px);
    }
  
    .cd-overlay.is-visible.search-is-visible, .nav-on-left .cd-overlay.is-visible.search-is-visible {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -ms-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }
  }
  
  /* -------------------------------- 
  
  support for no js 
  
  -------------------------------- */
  
  .no-js {
    .cd-primary-nav {
      position: relative;
      height: auto;
      width: 100%;
      overflow: visible;
      visibility: visible;
      z-index: 2;
    }
  
    .cd-search {
      position: relative;
      top: 0;
      opacity: 1;
      visibility: visible;
    }
  }
  
  @media only screen and (min-width: 960px) {
    .no-js {
      .cd-primary-nav {
        position: absolute;
        z-index: 3;
        display: inline-block;
        width: auto;
        top: 0;
        right: 150px;
        padding: 0;
      }
  
      .nav-is-fixed .cd-primary-nav {
        position: fixed;
      }
    }
  }
  
  @media only screen and (max-width: 768px) {
    .cd-primary-nav .cd-nav-gallery {
      .cd-nav-item {
        img {
          display: none;
        }
  
        padding: 25px 10px 10px 15px;
        height: auto;
        line-height: 1.4;
      }
  
      ol {
        margin-left: 25px;
  
        li {
          a {
            font-size: 0.85em;
            padding: 0 10px 10px;
            font-weight: 400;
            border-bottom: 1px solid #3a3f40;
          }
  
          &:last-child a {
            border-bottom: none;
          }
        }
      }
    }
  }